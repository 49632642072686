<template>
  <div class="operation_diagram_con">
    <h5 class="oper_tit">{{$t('casesDetail.casesChildren.operationChildren.fabh')}}：{{curePlanId}}</h5>
    <ul class="oper_ul" @click="changeTab">
      <li
        class="oper_single"
        :class="tabKey === '1' ? 'oper_single_active' : ''"
        data-key="1">
        {{$t('casesDetail.casesChildren.operationChildren.czsyb')}}
        <span
          class="o_icon"
          data-key="1"
        ></span>
      </li>
      <li
        class="oper_single"
        :class="tabKey === '2' ? 'oper_single_active' : ''"
        data-key="2">
        {{$t('casesDetail.casesChildren.operationChildren.d3py')}}
        <span
          class="o_icon"
          data-key="2"></span>
      </li>
      <li
        class="oper_single"
        :class="tabKey === '3' ? 'oper_single_active' : ''"
        data-key="3">
        {{$t('casesDetail.casesChildren.operationChildren.zlb')}}
        <span
          class="o_icon"
          data-key="3"></span>
      </li>
      <li
        class="oper_single"
        :class="tabKey === '4' ? 'oper_single_active' : ''"
        data-key="4">
        {{$t('casesDetail.casesChildren.operationChildren.yxzl')}}
        <span
          class="o_icon"
          data-key="4"></span>
      </li>
      <li
        class="oper_single"
        :class="tabKey === '5' ? 'oper_single_active' : ''"
        data-key="5"
      >
        {{$t('casesDetail.casesChildren.operationChildren.lsxgyj')}}
        <span
          class="o_icon"
          data-key="5"
        ></span>
      </li>
    </ul>
    <oper-diagram
      v-show="tabKey === '1'"
      :details="details"
      :teethKeyObj="teethKeyObj.attachmentTeethKeyObj"
      :cutInfoObj="cutInfoObj"
      :babyTeethKeyObj="babyTeethKeyObj"
    />
    <div style="height: 1000px" v-show="tabKey === '2'">
      <OperationAnimation
        :toothView="toothView"
        :isShowTeeth="isShowTeeth"
      />
    </div>
    <oper-preview
      v-if="(tabKey === '3' || tabKey === '4') && commonCaseCureDetail.detail.id"
      :selectedTabKey="tabKey"
      :details="details"
      :userInfo="userInfo"
      :teethKeyObj="teethKeyObj"
      :babyTeethKeyObj="babyTeethKeyObj"
      :commonTeeth="commonTeeth" :uploadIntervalList="uploadIntervalList"
      :crowdTeethList="crowdTeethList"
    />
    <proposal-history
      v-show="tabKey === '5'"
      :isType="isType"/>
  </div>
</template>

<script>
  import OperDiagram from './operDiagram';
  import OperPreview from './operPreview_copy';
  import ProposalHistory from './proposalHistory';
  import OperationAnimation from './operationAnimation';
  import {detailCurePlan} from 'common/api/cases';
  import {splitText} from 'common/js/util';
  import {
    mapActions,
    mapGetters
  } from 'vuex';
  import {filter,map,each} from 'lodash';

  export default {
    data() {
      return {
        tabKey: sessionStorage.getItem('operationKey') || '1',
        teethTypeObj: {
          '1': 'teeth_missing', // 缺失
          '2': 'teeth_no_move', // 不可移动
          '3': 'teeth_no_design', // 不可设计
          '4': 'teeth_extraction' // 拔牙
        },
        details: {},
        userInfo: {},
        teethKeyObj: {
          attachmentTeethKeyObj: {}
        },
        babyTeethKeyObj: {},
        cutInfoObj: {},
        teethTypeClass: {
          '1': 'teeth_missing',
          '6': 't__s_tag_fj'
        },
        curePlanId: '',
        currentTxtObj: {
          '1': this.$t('casesDetail.casesChildren.operationChildren.czsyb'),
          '2': this.$t('casesDetail.casesChildren.operationChildren.d3py'),
          '3': this.$t('casesDetail.casesChildren.operationChildren.zlb'),
          '4': this.$t('casesDetail.casesChildren.operationChildren.yxzl'),
          '5': this.$t('casesDetail.casesChildren.operationChildren.lsxgyj')
        },
        toothView: '',
        isShowTeeth: false,

        commonTeeth: [],
        uploadIntervalList: [],
        crowdTeethList: []
      }
    },
    props: {
      isType: {
        type: String,
        default: '1'
      }
    },
    computed:{
      ...mapGetters({
        'commonCaseCureDetail': 'getCommonCaseCureDetail',//治疗表详情
      }),
    },
    methods: {
      ...mapActions({
        'getCaseCureDetail' : 'actionGetCaseCureDetail',//治疗表详情
      }),
      initPlan() {
        this.getCaseCureDetail({id:this.curePlanId}).then(obj => {
          this.$emit('getPlanMsg', obj);
          const {upjawSideNote = '{}', downjawSideNote = '{}', cureNote = {}, toothViewCure = '', toothViewTarget = ''} = obj;
          let toothView = toothViewCure ? toothViewCure : toothViewTarget;
          const {cureNoteDetailList = []} = cureNote;
          let noteList = [], otherNoteObj = {};
          cureNoteDetailList.forEach(item => {
            if(item.step === '其他临床操作说明') {
              otherNoteObj = item;
            }else {
              noteList.push(item);
            }
          });
          const note = {
            noteList,
            otherNoteObj
          };
          sessionStorage.setItem('cureSummary', JSON.stringify(note));
          this.toothView = localStorage.getItem('viewPrefix') + toothView;
          this.isShowTeeth = !!toothView;
          const {upjawSideFront, upjawSideRightBack, upjawSideLeftBack} = JSON.parse(upjawSideNote || {});
          const {downjawSideFront, downjawSideRightBack, downjawSideLeftBack} = JSON.parse(downjawSideNote || {});
          const bk = this.$t('cases.createPreview.bk');
          const qy = this.$t('cases.createPreview.qy');
          const ychy = this.$t('cases.createPreview.ychy');
          const zchy = this.$t('cases.createPreview.zchy');
          this.details = {
            ...obj,
            upjawSideNote: `${upjawSideFront ? `${qy}$${upjawSideFront}` : ''}${upjawSideRightBack ? `>${ychy}$${upjawSideRightBack}` : ''}${upjawSideLeftBack ? `>${zchy}$${upjawSideLeftBack}` : ''}`,
            downjawSideNote: `${downjawSideFront ? `${qy}$${downjawSideFront}` : ''}${downjawSideRightBack ? `>${ychy}$${downjawSideRightBack}` : ''}${downjawSideLeftBack ? `>${zchy}$${downjawSideLeftBack}` : ''}`
          };
          this.userInfo = obj.caseInfo.userInfo;
          const {toothInfoList, cutInfoList} = obj;
          this.toothList = toothInfoList;
          if (Array.isArray(toothInfoList)) {
            const babyObj = {};
            const missingObj = {}; // missingTeethKeyObj
            const noMoveObj = {}; // noMoveTeethKeyObj
            const noDesignObj = {}; // noDesignTeethKeyObj
            const extractionObj = {}; // extractionTeethKeyObj
            const attachmentObj = {}; // attachmentTeethKeyObj
            toothInfoList.forEach(item => {
              if(item.type === '1') {
                missingObj[item.number] = {
                  type: '1',
                  number: item.number,
                  className: 'teeth_missing'
                };
              }else if(item.type === '2') {
                noMoveObj[item.number] = {
                  type: '2',
                  number: item.number,
                  className: 'teeth_no_move'
                };
              }else if(item.type === '3') {
                noDesignObj[item.number] = {
                  type: '3',
                  number: item.number,
                  className: 'teeth_no_design'
                };
              }else if(item.type === '4') {
                extractionObj[item.number] = {
                  type: '4',
                  number: item.number,
                  className: 'teeth_extraction'
                };
              }else if (item.type === '6') {
                attachmentObj[item.number] = {
                  type: '6',
                  number: item.number,
                  className: 't__s_tag_fj'
                };
              }else if (item.type === '7') {
                babyObj[item.number] = {
                  id: item.id,
                  type: '7',
                  number: item.number
                };
              }
            });
            this.teethKeyObj = {
              missingTeethKeyObj: missingObj,
              noMoveTeethKeyObj: noMoveObj,
              noDesignTeethKeyObj: noDesignObj,
              extractionTeethKeyObj: extractionObj,
              attachmentTeethKeyObj: attachmentObj,
            };
            this.babyTeethKeyObj = babyObj;
          }
          if (Array.isArray(cutInfoList)) {
            let obj = {};
            cutInfoList.forEach(item => {
              obj[+item.toothEnd] = {
                ...item
              }
            });
            this.cutInfoObj = obj;
          }

          this.uploadIntervalList = filter(this.details.toothInfoList, item => item.type === '5');
          this.crowdTeethList = filter(this.details.toothInfoList, item => item.type === '9');

          let noMoveList = filter(this.details.toothInfoList, item => item.type === '2');
          let noDesignList = filter(this.details.toothInfoList, item => item.type === '3');
          let removeList = filter(this.details.toothInfoList, item => item.type === '4');
          let mainList = filter(this.details.toothInfoList, item => item.type === '8');
          let untreatedList = [...noMoveList,...noDesignList,...removeList];
          if(untreatedList.length){
            let teethTypeObj = {
              '2' : 'noMove',
              '3' : 'noDesign',
              '4' : 'remove'
            };
            this.commonTeeth = map(mainList, item => {
              let obj;
              each(untreatedList, it => {
                if(item.number === it.number && !item.nextNumber){
                  obj = extend({},item,obj,{[teethTypeObj[it.type]]: teethTypeObj[it.type]});
                }
              });
              return obj ? obj : item;
            });
          }else{
            this.commonTeeth = filter(this.details.toothInfoList, item => !item.nextNumber) || []
          }
        });
      },
      changeTab(ev) {
        const target = ev.target;
        const tabKey = target.getAttribute('data-key');
        if (tabKey) {
          sessionStorage.setItem('operationKey', tabKey);
          this.tabKey = tabKey;
          this.$emit('changeTabKey', tabKey);
        }
      }
    },
    components: {
      OperDiagram,
      OperPreview,
      ProposalHistory,
      OperationAnimation
    },
    created() {
      let id = localStorage.getItem('curePlanId') || sessionStorage.getItem('curePlanId') || '';
      sessionStorage.setItem('curePlanId', id);
      localStorage.removeItem('curePlanId');
      sessionStorage.removeItem('planMsg');
      if (id) {
        this.curePlanId = id;
        this.initPlan();
      }
    },
  }
</script>

<style scoped lang="scss">
  .operation_diagram_con {
    color: $main_theme_color_333;
    font-size: 0.16rem;
    .oper_tit {
      color: $main_theme_color_333;
      font-size: 0.24rem;
      margin-bottom: 0.2rem;
    }
    .oper_ul {
      display: flex;
      align-items: center;
      color: $main_theme_color_333;
      font-size: 0.18rem;
      border-bottom: #eee;
      span {
        display: inline-block;
      }
      .oper_single {
        min-width: 1.48rem;
        padding: 0 0.2rem;
        height: 0.46rem;
        line-height: 0.46rem;
        text-align: center;
        border-top-left-radius: 0.06rem;
        border-top-right-radius: 0.06rem;
        margin-right: 0.02rem;
        background-color: #fff;
        position: relative;
        cursor: pointer;
        .o_icon {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0.16rem;
          height: 0.08rem;
          background-size: 100% 100%;
          background-image: url("/static/images/nav_sp.png");
        }
      }
      .oper_single_active {
        background-color: $main_theme_color;
        color: #fff;
      }
    }
    .oper_box{
      padding: 1rem 1rem 3rem;
      background-color: #fff;
      .oper_p{
        font-size: 0.18rem;
        color: $main_theme_color_333;
        line-height: 0.26rem;
      }
    }
  }
</style>

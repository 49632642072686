<template>
  <div class="oper_diagram">
    <teeth-view v-if="['A', 'I'].includes(get(details,'caseInfo.cureType')) || !get(details,'caseInfo.cureType')"
      :babyTeethKeyObj="babyTeethKeyObj" :teethKeyObj="teethKeyObj" :cutInfoObj="cutInfoObj"/>
    <Children-Teeth-View :teethList="get(details,'toothInfoList')" :cutInfoList="get(details,'cutInfoList')"
                         v-if="['B','C'].includes(get(details,'caseInfo.cureType'))" />
    <diagram-msg
      :details="details"
    />
  </div>
</template>

<script>
  import TeethView from 'components/teeth-com/teethView';
  import DiagramMsg from './diagramMsgCopy';
  import {caseNoteList} from 'common/api/cases';
  import ChildrenTeethView from '../../../../../components/treatment-table/teeth_view/infancy';
  import {get} from 'lodash';

  export default {
    data() {
      return {}
    },
    methods:{
      get,
    },
    props: {
      details: {
        type: Object,
        default: () => ({})
      },
      teethKeyObj: {
        type: Object,
        default: () => ({})
      },
      cutInfoObj: {
        type: Object,
        default: () => ({})
      },
      babyTeethKeyObj: {
        type: Object,
        default: () => ({})
      }
    },
    created() {
      const curePlanId = localStorage.getItem('curePlanId') || sessionStorage.getItem('curePlanId') || '';
      sessionStorage.setItem('curePlanId', curePlanId);
      localStorage.removeItem('curePlanId');
    },
    components: {
      TeethView,
      DiagramMsg,
      ChildrenTeethView
    }
  }
</script>

<style scoped lang="scss">
  .oper_diagram{
    background-color: #fff;
    padding: 0.64rem 0.85rem 0.82rem;
    border-radius: 0.1rem;
  }
</style>

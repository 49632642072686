<template>
  <div class="op_animation" :class="!isShowTeeth ? 'gray_bc' : ''">
    <iframe v-if="isShowTeeth" width="100%" height="100%" :src="toothView" frameborder="0" id="iFrame"></iframe>
    <div v-else class="no_data">
      <!--<p class="no_data_p">{{$t('common.common.zzzhz')}}</p>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toothView: {
      type: String,
      default: ''
    },
    toothViewVersion: {
      required: false,
    },
    isShowTeeth: {
      type: Boolean,
      default: false
    },
    picPrefix: {
      required: false
    }
  },
  data() {
    return {
      host3d: ''
    }
  },
  mounted() {
    const url = this.picPrefix === 'aismile' ? `https://3d.aismile.cn` : `http://3d.test.aismile.cn`;
    this.host3d = url;
    window.addEventListener("message", (e) => {
      if (e.origin !== url) {
        return null;
      }
      const { data } = e;
      this.$emit('canEdit', data.canEdit)
    });
  }
}
</script>

<style scoped lang="scss">
.op_animation {
  height: 100%;
  box-sizing: border-box;
  background-color: #38383A;
  position: relative;

  .no_data {
    font-size: 0.25rem;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}

.gray_bc {
  background-color: #38383A !important;
}
</style>

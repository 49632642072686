<template>
  <div class="diagram_msg">
    <div class="oper_box">
      <p class="mb10" :class="{ main_theme_color_333: isOldCase }">
        <span
          >{{ $t("casesDetail.casesChildren.operationChildren.hzxm") }}：
          <b>
            {{ details.userName }}
          </b>
        </span>
        <span style="margin: 0 0.2rem"
          >{{ $t("casesDetail.casesChildren.operationChildren.sjrq") }}：<b>{{
            get(details, "designDate") || "-"
          }}</b></span
        >
      </p>
      <p
        :class="{ main_theme_color_333: isOldCase }"
        v-if="cureNoteDetail.type === '2'"
      >
        <span>{{
          $t("casesDetail.casesChildren.operationChildren.skjzbz")
        }}</span
        >：<b>
          {{ get(details, "correctStepUpjaw") == 0 ? "" : "1-"
          }}{{ get(details, "correctStepUpjaw") || "-" }}</b
        >
        <span style="margin-left: 0.2rem">{{
          $t("casesDetail.casesChildren.operationChildren.xkjzbz")
        }}</span
        >：<b>
          {{ get(details, "correctStepDownjaw") == 0 ? "" : "1-"
          }}{{ get(details, "correctStepDownjaw") || "-" }}</b
        >
      </p>
      <p v-if="cureNoteDetail.type === '1'">
        {{ $t("casesDetail.casesChildren.operationChildren.yjjzzq") }}
        {{ get(details, "correctPeriod") }}
        {{ $t("casesDetail.casesChildren.operationChildren.y") }}
      </p>
    </div>
    <div class="diagram_box">
      <h5 :class="{ main_theme_color_333: isOldCase }" class="diagram_box_h5">
        <img
          class="mr10"
          src="../../../../../common/imgs/3d/dia_msg_init.png"
          alt=""
        />
        {{ $t("casesDetail.casesChildren.operationChildren.lccz") }}
      </h5>
      <ul class="diagram_ul">
        <li
          v-for="(item, index) in cureNote"
          :key="item.id"
          :class="{ diagram_single_old: isOldCase }"
          class="diagram_single"
        >
          <div class="single_left">
            ●
            <span v-if="schemeType === 'cure' && cureNote.length - 1 !== index">
              {{ $t("casesDetail.casesChildren.lookWearingRecord.d") }} </span
            >{{
              item.step === "其他临床操作说明"
                ? $t("common.message.qtlcczsm")
                : item.step
            }}{{ language == "en_US" ? "：" : ""
            }}<span
              :class="[language == 'en_US' && 'ml4']"
              v-if="
                schemeType === 'cure' &&
                cureNote.length - 1 !== index &&
                language !== 'en_US'
              "
            >
              {{ $t("casesDetail.casesDetail.bu")
              }}{{ language !== "en_US" ? "：" : "" }}</span
            >
          </div>
          <div class="single_right">
            <p
              class="single_right_p"
              v-for="(cItem, cIndex) in item.note"
              :key="`note_${cIndex}`"
            >
              {{ cItem }}
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="details.helpFlag == 1" class="diagram_box mt30">
      <h5 :class="{ main_theme_color_333: isOldCase }" class="diagram_box_h5">
        <img
          class="mr10"
          src="../../../../../common/imgs/3d/dia_msg2.png"
          alt=""
        />
        {{ $t("casesDetail.casesChildren.operationChildren.fzjkyj") }}
      </h5>
      <div class="diagram_single wsp">
        {{ details.visitSuggest }}
      </div>
    </div>
  </div>
</template>

<script>
import { splitText } from "common/js/util";
import { get } from "lodash";
import { mapState } from "vuex";
export default {
  data() {
    return {
      cureNote: [],
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
  },
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
    schemeType: {
      type: String,
      default: "",
    },
    isOldCase: {
      type: Boolean,
      default: false,
    },
    cureNoteDetail: {
      required: false
    }
  },
  methods: {
    get,
  },
  watch: {
    schemeType: {
      deep: true,
      immediate: true,
      handler(val) {},
    },
    details: {
      handler(obj) {
        const { cureNoteDetailList = [] } = obj;
        this.cureNote = cureNoteDetailList.map((item) => ({
          ...item,
          note: splitText(item.note),
        }));
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.diagram_msg {
  .oper_box {
    margin-bottom: 0.2rem;
    p {
      line-height: 0.24rem;
      font-size: 0.16rem;
      display: flex;
      align-items: center;
      b {
        font-weight: 500;
      }
    }
  }
  .diagram_box {
    .diagram_box_h5 {
      display: flex;
      align-items: center;
      font-size: 0.16rem;
      color: #ffffff;
    }
    .diagram_single {
      margin-top: 10px;
      display: flex;
      word-break: break-all;
      background: #58595a;
      padding: 0.2rem 0.1rem;
      cursor: default;
      font-size: 14px;
      border-radius: 4px;
      line-height: 0.2rem;
      .single_left {
        width: 1.94rem;
        padding-top: 0.04rem;
      }
      .single_right {
        flex: 1;
        position: relative;
        .single_right_p {
          margin-bottom: 0.12rem;
          line-height: 0.26rem;
        }
      }
    }
    .diagram_single_old {
      background: #f4f4f4;
      color: $main_theme_color_333;
    }
  }
}
</style>

<template>
  <div class="operation_diagram">
    <back-step
      :goList="[{
        url: '/cases',
        name: $t('casesDetail.casesChildren.common.bl')
      }, {
        url: '/cases/detail',
        name: $t('casesDetail.casesChildren.common.blxq')
      }]"
      :currentTxt="currentTxtObj[tabKey]"
      v-if="!isReviewPlan"
    />
    <operation-view-con @changeTabKey="changeTabKey"/>
  </div>
</template>

<script>
  import BackStep from '../childrenPublic/backStep';
  import OperationViewCon from './operationChildren/operationViewCon';

  export default {
    data() {
      return {
        isReviewPlan: false,
        currentTxtObj: {
          '1': this.$t('casesDetail.casesChildren.operationChildren.czsyb'),
          '2': this.$t('casesDetail.casesChildren.operationChildren.d3py'),
          '3': this.$t('casesDetail.casesChildren.operationChildren.zlb'),
          '4': this.$t('casesDetail.casesChildren.operationChildren.yxzl'),
          '5': this.$t('casesDetail.casesChildren.operationChildren.lsxgyj')
        },
        tabKey: '1'
      }
    },
    created() {
      this.isReviewPlan = window.location.href.includes('reviewPlan');
    },
    methods: {
      changeTabKey(key) {
        this.tabKey = key;
        document.title = this.currentTxtObj[key];
      }
    },
    components: {
      BackStep,
      OperationViewCon
    }
  }
</script>

<style scoped lang="scss">
  .operation_diagram {
    margin: 0 auto;
    width: 13.44rem;
    color: $main_theme_color_333;
    font-size: 0.16rem;
    .oper_tit {
      color: $main_theme_color_333;
      font-size: 0.24rem;
      margin-bottom: 0.2rem;
    }
    .oper_ul {
      display: flex;
      align-items: center;
      color: #666666;
      font-size: 0.18rem;
      border-bottom: #eee;
      span {
        display: inline-block;
      }
      .oper_single {
        width: 1.48rem;
        height: 0.46rem;
        line-height: 0.46rem;
        text-align: center;
        border-top-left-radius: 0.06rem;
        border-top-right-radius: 0.06rem;
        margin-right: 0.02rem;
        background-color: #fff;
        position: relative;
        cursor: pointer;
        .o_icon {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0.16rem;
          height: 0.08rem;
          background-size: 100% 100%;
          background-image: url("/static/images/nav_sp.png");
        }
      }
      .oper_single_active {
        background-color: $main_theme_color;
        color: #fff;
      }
    }
  }
</style>
